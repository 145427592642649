import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/layout'
import EmptyLayout from '@/layout/empty'

const baseRoutes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('@/views/index'),
                meta: {
                    title: '清单法考'
                }
            }
        ]
    },
    {
        path: 'reload',
        name: 'reload',
        component: () => import('@/views/reload')
    },
    {
        path: '/login',
        component: EmptyLayout,
        children: [
            {
                path: '/',
                name: 'login',
                component: () => import('@/views/login'),
                meta: {
                    title: '登录'
                }
            }
        ]
    }
]

// 主观题题库
import subjectiveBank from "./module/bank/subjectiveBank";
import subjectiveBankDetail from "@/router/module/bank/subjectiveBankDetail";

// 我的
// -- 我的模考
import mySubjectivePaper from "./module/my/mySubjectivePaper";

// 法条学习
import lawTree from "./module/law/lawTree";

// 专栏信息
import topic from "@/router/module/topic/topic"

// 模考
import subjectivePaper from "@/router/module/paper/subjectivePaper";

// 关于
import app from "@/router/module/about/app";

// 业务路由
const routes = baseRoutes.concat(
    subjectiveBank,
    subjectiveBankDetail,
    mySubjectivePaper,
    lawTree,
    topic,
    subjectivePaper,
    app
)

const router = new VueRouter({
    mode: process.env.VUE_APP_ROUTER_MODE,
    routes
})

// 解决路由在 push/replace 了相同地址报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        //判断是否有标题
        document.title = to.meta.title + '-' + '清单法考'
    }
    next()
})


export default router