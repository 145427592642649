import Vue from 'vue'
import Vuex from 'vuex'
import {Loading} from 'element-ui'

let loadingInstance
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem("token") ? localStorage.getItem("token") : '',
        browseHistory: localStorage.getItem("browseHistory") ? JSON.parse(localStorage.getItem("browseHistory")) : [],
        userInfo: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {},
        classInfo: localStorage.getItem("classInfo") ? JSON.parse(localStorage.getItem("classInfo")) : {},
        timer: null
    },
    mutations: {
        setLoading(state, data) {
            if (data) {
                if (state.timer) {
                    // 已经有一个了，先清除之前的加载
                    clearTimeout(state.timer)
                    loadingInstance.close()
                }
                state.timer = setTimeout(function () {
                    loadingInstance = Loading.service({
                        text: '正在加载中'
                    })
                }, 500)
            } else {
                clearTimeout(state.timer)
                if (loadingInstance) {
                    loadingInstance.close()
                }
                state.timer = null
            }
        },
        clearBrowseHistory(state) {
            state.browseHistory = []
            localStorage.setItem("browseHistory", JSON.stringify([]))
        },
        addBrowseHistory(state, data) {
            if (state.browseHistory.length >= 10) {
                state.browseHistory.pop()
            }
            state.browseHistory.unshift(data)
            localStorage.setItem("browseHistory", JSON.stringify(state.browseHistory))
        },
        setToken(state, data) {
            state.token = data
            localStorage.setItem("token", state.token)
        },
        setUserData(state, data) {
            state.userInfo = data
            localStorage.setItem("userInfo", JSON.stringify(state.userInfo))
        },
        setClassInfo(state, data) {
            state.classInfo = data
            localStorage.setItem("classInfo", JSON.stringify(state.classInfo))
        },
        removeUserData(state) {
            state.token = ''
            localStorage.removeItem("token")
            state.userInfo = {}
            localStorage.removeItem("userInfo")
        }
    },
    actions: {},
    modules: {}
})
