<template>
    <div>
        <!-- 回到顶部组件 -->
        <el-backtop />

        <!-- 头部导航栏 -->
        <page-header />

        <!-- 内容区域 -->
        <div style="padding-top: 100px;">
            <!-- 页面主体 -->
            <router-view style="width: 1200px; margin: 0 auto; min-height: calc(100vh - 370px)"/>

            <!-- 底部版权信息 -->
            <copy-right />
        </div>
    </div>
</template>

<script>
import PageHeader from "./PageHeader";
import CopyRight from "./CopyRight";
export default {
    name: "index",
    components: {CopyRight, PageHeader}
}
</script>

<style scoped lang="scss">
.page-body {
    background-color: $sr-main-bg;
    min-height: 100vh;
}

::v-deep .el-backtop {
    color: $sr-main-color;
}
</style>