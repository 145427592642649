import Empty from '@/layout/empty'

export default {
    path: '/subjectivePaper',
    component: Empty,
    redirect: '/subjectivePaper/index',
    name: 'subjectivePaper',
    meta: {
        title: '主观题模考'
    },
    children: [
        {
            path: 'paperChoose',
            name: 'subjectivePaperChoose',
            component: () => import('@/views/paper/subjectivePaper/paperChoose')
        }, {
            path: 'paperLogin',
            name: 'subjectivePaperLogin',
            component: () => import('@/views/paper/subjectivePaper/paperLogin')
        }, {
            path: 'paperBefore',
            name: 'subjectivePaperBefore',
            component: () => import('@/views/paper/subjectivePaper/paperBefore')
        }, {
            path: 'paperDetail',
            name: 'subjectivePaperDetail',
            component: () => import('@/views/paper/subjectivePaper/paperDetail')
        }, {
            path: 'paperEnd',
            name: 'subjectivePaperEnd',
            component: () => import('@/views/paper/subjectivePaper/paperEnd')
        }
    ]
}