import Layout from '@/layout'

export default {
    path: '/subjectiveBank',
    component: Layout,
    redirect: '/subjectiveBank/index',
    name: 'subjectiveBank',
    meta: {
        title: '主观题题库'
    },
    children: [
        {
            path: 'index',
            name: 'subjectiveBankIndex',
            component: () => import('@/views/bank/subjectiveBank/index'),
            meta: {
                title: '主观题题库'
            }
        },
        {
            path: 'detail',
            name: 'subjectiveBankDetail',
            component: () => import('@/views/bank/subjectiveBank/detail'),
            meta: {
                title: '题目列表'
            }
        }
    ]
}