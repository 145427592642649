import Layout from '@/layout'

export default {
    path: '/law',
    component: Layout,
    redirect: '/law/index',
    name: 'law',
    meta: {
        title: '法条学习'
    },
    children: [
        {
            path: 'index',
            name: 'lawTreeIndex',
            component: () => import('@/views/law/lawTree/index'),
            meta: {
                title: '法条学习'
            }
        }
    ]
}