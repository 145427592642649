<template>
    <div class="copyright">
        <div class="content">
            <el-row type="flex" align="top">
                <el-col :span="4">
                    <div style="width: 165px;">
                        <el-image fit="fill" :src="require('@/assets/images/logo-long.png')"/>
                    </div>
                </el-col>
                <el-col :span="11">
                    <div class="desc">
                        哈中教育以“百度之外，才是价值所在”为办学基点，做“有思想、有方案、有温度”的教育机构，旨在培养有情怀、会思辨、专业精进的杰出法律人才。与广大法律人携手实现海内外名校升学、理想就业的人生愿景。法律常新，青年常青，携手同行！
                    </div>
                </el-col>
                <el-col :span="3">
                    <div>
                        <div class="about">
                            关于我们
                        </div>
                        <div class="about">
                            <router-link :to="{name: 'userAgreement'}" target="_blank">
                                用户协议
                            </router-link>
                        </div>
                        <div class="about">
                            <router-link :to="{name: 'userPrivacy'}" target="_blank">
                                隐私政策
                            </router-link>
                        </div>
                        <div class="code-tips" style="text-align: left;">
                            xcx@harchinaedu.com
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" style="display: flex;">
                    <div style="width: 100px;">
                        <el-image :src="require('@/assets/images/list-mini.jpg')"/>
                        <p class="code-tips">
                            清单法考小程序
                        </p>
                    </div>
                    <div style="width: 100px; margin-left: 60px;">
                        <el-image :src="require('@/assets/images/wechat.png')"/>
                        <p class="code-tips">
                            微信公众号
                        </p>
                    </div>
                </el-col>
            </el-row>
            <p class="copyright-tips">
                @2020-2022 北京哈中教育科技有限公司 版权所有
                <el-image :src="require('@/assets/images/beianIcon.png')" style="margin-left: 10px; margin-right: 3px;"/>
                <el-link href="https://beian.miit.gov.cn/" target="_blank" :underline="false" class="beian">
                    京ICP备19046095号
                </el-link>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "CopyRight"
}
</script>

<style scoped lang="scss">
.copyright {
    background-color: #36363d;
    margin-top: 30px;
    padding: 20px 0;

    .content {
        width: 1200px;
        margin: 0 auto;
        color: #ffffff;

        .desc {
            color: #9c9c9d;
            font-size: 14px;
            line-height: 28px;
            text-align: justify;
            width: 440px;
        }

        .copyright-tips {
            padding: 20px 0;
            text-align: center;
            font-size: 12px;
            border-top: 1px solid #3f444c;
            clear: both;
            color: #9c9c9d;

            .beian {
                color: #9c9c9d;
                font-size: 12px;
            }
        }

        .about {
            font-size: 16px;
            margin-bottom: 20px;
            cursor: pointer;

            a {
                text-decoration: none !important;
                color: #FFFFFF;
            }
        }

        .code-tips {
            opacity: 0.6;
            font-family: Helvetica,serif;
            font-size: 14px;
            color: #FFFFFF;
            margin-top: 15px;
            text-align: center;
        }
    }
}
</style>