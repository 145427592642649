import Layout from '@/layout'

export default {
    path: '/mySubjectivePaper',
    component: Layout,
    redirect: '/mySubjectivePaper/index',
    name: 'mySubjectivePaper',
    meta: {
        title: '我的'
    },
    children: [
        {
            path: 'index',
            name: 'mySubjectivePaperIndex',
            component: () => import('@/views/my/subjectivePaper/index'),
            meta: {
                title: '我的模考'
            }
        }
    ]
}