import Empty from '@/layout/empty'

export default {
    path: '/app',
    component: Empty,
    redirect: '/app/appDownload',
    name: 'app',
    meta: {
        title: '柏杜法考APP'
    },
    children: [
        {
            path: 'appDownload',
            name: 'appDownload',
            component: () => import('@/views/about/appDownload'),
            meta: {
                title: '柏杜法考APP-下载'
            }
        }
    ]
}