import axios from 'axios'
import router from '@/router/index'
import store from '@/store/index'
import {Message} from "element-ui";

let loadingInstance

// 初始化
const api = axios.create({
    baseURL: process.env.VUE_APP_API_ROOT,
    timeout: 500000,
    responseType: 'json'
    // withCredentials: true
})


const toLogin = () => {
    router.push({
        name: 'login',
        query: {
            redirect: router.currentRoute.fullPath
        }
    })
}

// 配置请求拦截器
api.interceptors.request.use(
    request => {
        request.headers.token = store.state.token
        return request
    }
)


api.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200) {
            // 如果接口请求时发现 token 失效，则立马跳转到登录页
            if (res.code === 201) {
                store.commit('removeUserData')
                toLogin()
                return false
            }

            // 其他错误，信息提示
            if (res.msg) {
                Message.warning(res.msg)
            }

            return Promise.reject(res)
        }

        return Promise.resolve(res)
    },
    error => {
        if (loadingInstance) {
            loadingInstance.close()
        }
        return Promise.reject(error)
    }
)

export default api
