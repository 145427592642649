<template>
  <div class="page-main">
    <div v-if="title" class="title-container">{{ title }}</div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'PageMain',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.page-main {
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  padding: 5px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 12px #ebedf0;
}

.title-container {
    width: 100%;
    padding: 7px 10px;
    margin-left: -8px;
    margin-top: -8px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
</style>
