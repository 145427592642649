<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
export default {
    name: "empty"
}
</script>