import Layout from '@/layout'

export default {
    path: '/topic',
    component: Layout,
    redirect: '/topic/index',
    name: 'topic',
    meta: {
        title: '专栏课'
    },
    children: [
        {
            path: 'detail',
            name: 'topicDetail',
            component: () => import('@/views/topic/detail'),
            meta: {
                title: '专栏课'
            }
        }
    ]
}