<template>
    <div class="bar">
        <div class="content">
            <el-row type="flex" align="middle">
                <el-col :span="19">
                    <div class="title">
                        <!-- logo  -->
                        <div class="logo">
                            <router-link :to="{name: 'index'}">
                                <el-image :src="require('@/assets/images/logo-long.png')"/>
                            </router-link>
                        </div>
                        <!-- 标题 -->
                        <div>
                            <el-menu :default-active="activeIndex"
                                     router
                                     mode="horizontal"
                                     active-text-color="#1a8ff6"
                                     text-color="#3a3a3a" >
                                <el-menu-item index="/">模考</el-menu-item>
                                <el-menu-item index="/subjectiveBank/index">
                                    {{ isMember ? '内部题库' : '主观题题库' }}
                                </el-menu-item>
                                <el-menu-item index="/mySubjectivePaper/index">我的套卷</el-menu-item>
                                <el-menu-item index="/law/index">法条学习</el-menu-item>
                            </el-menu>
                        </div>
                    </div>
                </el-col>
                <el-col :span="5">
                    <div class="title" style="float: right; align-items: center;">
                        <div class="select-title">
                            <router-link v-if="!$store.state.token" :to="{name: 'login'}" style="text-decoration: none; color: #3a3a3a;">
                                <i class="el-icon-s-custom" style="margin-right: 10px; font-size: 20px;"></i>登录
                            </router-link>
                            <el-tooltip v-else effect="dark" content="点击退出登录" placement="bottom-end">
                                <div style="display: flex; align-items: center; color:#3a3a3a;" @click="logout">
                                    <el-avatar :src="$store.state.userInfo.headImgUrl" :size="45"
                                               style="margin-right: 10px;"/>
                                    {{ $store.state.userInfo.name }}
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageHeader",
    data() {
        return {
            activeIndex: '/',
            isMember: false
        }
    },
    mounted() {
        if (this.$store.state.userInfo) {
            if (!this.$store.state.userInfo.isRegister) {
                this.isMember = true
            }
        }

        this.setPath()
    },
    watch: {
        $route() {
            this.setPath()
        }
    },
    methods: {
        // 设置路由路径
        setPath() {
            // 识别身份
            this.activeIndex = this.$route.path
        },
        // 点击导航页
        handleSelect(key, keyPath) {
            console.log(key, keyPath)
        },
        // 退出登录
        logout() {
            this.$store.commit("setUserData", {})
            this.$store.commit("setToken", '')
            this.$router.push({name: 'login'})
        }
    }
}
</script>

<style>
.el-menu.el-menu--horizontal {
    border-bottom: none !important;
}
</style>

<style scoped lang="scss">
.bar {
    width: 100%;
    background: $sr-bg-color;
    box-shadow: 0 2px 6px 0 rgb(187 187 187 / 50%);
    margin-bottom: 20px;
    padding: 10px 0;
    height: 80px;
    position: fixed;
    top: 0;
    z-index: 999 !important;

    .content {
        width: 1200px;
        margin: 0 auto;

        .title {
            display: flex;
            align-items: center;

            .logo {
                margin-top: 15px;
                width: 130px;
                margin-right: 30px;
            }

            li {
                font-size: 18px;
            }

            .select-title {
                font-size: 18px;
                cursor: pointer;
            }
        }
    }
}
</style>