import Empty from '@/layout/empty'

export default {
    path: '/subjectiveBankDetail',
    component: Empty,
    redirect: '/subjectiveBankDetail/index',
    name: 'subjectiveBankDetail',
    meta: {
        title: '主观题测试'
    },
    children: [
        {
            path: 'subjectiveSingle',
            name: 'subjectiveSingle',
            component: () => import('@/views/bank/subjectiveBank/subjectiveSingle'),
            meta: {
                title: '主观题题目练习'
            }
        }, {
            path: 'subjectiveSingleEnd',
            name: 'subjectiveSingleEnd',
            component: () => import('@/views/bank/subjectiveBank/subjectiveSingleEnd'),
            meta: {
                title: '主观题题目练习'
            }
        }
    ]
}